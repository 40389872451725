<template>
    <div>
        Adventures ...
    </div>
</template>

<script>
export default {
    name: "Adventure",
    components: {},
    props: {},
    data() {
        return {
        }
    },
    mounted() {

    },
    methods: {},
    computed: {

    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
